import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'loyale-temp-token-sheet',
  templateUrl: './temp-token-sheet.component.html',
  styleUrls: ['./temp-token-sheet.component.scss']
})
export class TempTokenSheetComponent implements OnInit {

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
