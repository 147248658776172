/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AdminInsertView } from '../model/adminInsertView';
import { AdminInviteResultView } from '../model/adminInviteResultView';
import { AdminPutView } from '../model/adminPutView';
import { AdminResultView } from '../model/adminResultView';
import { AdminResultViewAltNoScheme } from '../model/adminResultViewAltNoScheme';
import { AdminResultViewWithAdditionalFields } from '../model/adminResultViewWithAdditionalFields';
import { AdminRoleResultView } from '../model/adminRoleResultView';
import { AdminSchemesWithRoleView } from '../model/adminSchemesWithRoleView';
import { CreateAdminView } from '../model/createAdminView';
import { InviteAdminView } from '../model/inviteAdminView';
import { TokenView } from '../model/tokenView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AdminService {

    protected basePath = 'https://api.staging.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add a SchemeId to an Admin
     * 
     * @param schemeId 
     * @param adminId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAddSchemePost(schemeId?: string, adminId?: string, observe?: 'body', reportProgress?: boolean): Observable<AdminResultView>;
    public apiAdminAddSchemePost(schemeId?: string, adminId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdminResultView>>;
    public apiAdminAddSchemePost(schemeId?: string, adminId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdminResultView>>;
    public apiAdminAddSchemePost(schemeId?: string, adminId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (schemeId !== undefined && schemeId !== null) {
            queryParameters = queryParameters.set('schemeId', <any>schemeId);
        }
        if (adminId !== undefined && adminId !== null) {
            queryParameters = queryParameters.set('adminId', <any>adminId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AdminResultView>('post',`${this.basePath}/api/Admin/AddScheme`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create an admin and assign the given role
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCreateAdminPost(body?: CreateAdminView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AdminResultViewWithAdditionalFields>>;
    public apiAdminCreateAdminPost(body?: CreateAdminView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AdminResultViewWithAdditionalFields>>>;
    public apiAdminCreateAdminPost(body?: CreateAdminView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AdminResultViewWithAdditionalFields>>>;
    public apiAdminCreateAdminPost(body?: CreateAdminView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<AdminResultViewWithAdditionalFields>>('post',`${this.basePath}/api/Admin/CreateAdmin`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminDisableTwoFactorAuthPost(body?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminDisableTwoFactorAuthPost(body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminDisableTwoFactorAuthPost(body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminDisableTwoFactorAuthPost(body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Admin/DisableTwoFactorAuth`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if an email is already in use.
     * 
     * @param adminEmail 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminFindEmailGet(adminEmail?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminFindEmailGet(adminEmail?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminFindEmailGet(adminEmail?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminFindEmailGet(adminEmail?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (adminEmail !== undefined && adminEmail !== null) {
            queryParameters = queryParameters.set('adminEmail', <any>adminEmail);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Admin/FindEmail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a List of Admin, depending on the Filter or Sorting
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AdminResultViewWithAdditionalFields>>;
    public apiAdminGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AdminResultViewWithAdditionalFields>>>;
    public apiAdminGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AdminResultViewWithAdditionalFields>>>;
    public apiAdminGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AdminResultViewWithAdditionalFields>>('get',`${this.basePath}/api/Admin`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export all Admins linked to the given Scheme
     * 
     * @param scheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGetSchemeAdminsExportGet(scheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<AdminResultView>>;
    public apiAdminGetSchemeAdminsExportGet(scheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AdminResultView>>>;
    public apiAdminGetSchemeAdminsExportGet(scheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AdminResultView>>>;
    public apiAdminGetSchemeAdminsExportGet(scheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiAdminGetSchemeAdminsExportGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AdminResultView>>('get',`${this.basePath}/api/Admin/GetSchemeAdminsExport`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return all Admins linked to the given Scheme
     * 
     * @param scheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGetSchemeAdminsGet(scheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<AdminResultViewAltNoScheme>>;
    public apiAdminGetSchemeAdminsGet(scheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AdminResultViewAltNoScheme>>>;
    public apiAdminGetSchemeAdminsGet(scheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AdminResultViewAltNoScheme>>>;
    public apiAdminGetSchemeAdminsGet(scheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiAdminGetSchemeAdminsGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AdminResultViewAltNoScheme>>('get',`${this.basePath}/api/Admin/GetSchemeAdmins`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get admin schemes with roles
     * 
     * @param adminId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminGetSchemesAdminIdGet(adminId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AdminSchemesWithRoleView>>;
    public apiAdminGetSchemesAdminIdGet(adminId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AdminSchemesWithRoleView>>>;
    public apiAdminGetSchemesAdminIdGet(adminId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AdminSchemesWithRoleView>>>;
    public apiAdminGetSchemesAdminIdGet(adminId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (adminId === null || adminId === undefined) {
            throw new Error('Required parameter adminId was null or undefined when calling apiAdminGetSchemesAdminIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AdminSchemesWithRoleView>>('get',`${this.basePath}/api/Admin/GetSchemes/${encodeURIComponent(String(adminId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an Admin
     * 
     * @param id 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminIdDelete(id: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminIdDelete(id: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminIdDelete(id: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminIdDelete(id: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiAdminIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Admin/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Admin
     * 
     * @param id 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminIdGet(id: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<AdminResultViewWithAdditionalFields>;
    public apiAdminIdGet(id: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdminResultViewWithAdditionalFields>>;
    public apiAdminIdGet(id: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdminResultViewWithAdditionalFields>>;
    public apiAdminIdGet(id: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiAdminIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AdminResultViewWithAdditionalFields>('get',`${this.basePath}/api/Admin/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Invite some to be an Admin of a Scheme
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminInvitePost(body?: InviteAdminView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminInvitePost(body?: InviteAdminView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminInvitePost(body?: InviteAdminView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminInvitePost(body?: InviteAdminView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Admin/invite`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of pending invites for Admins of a Scheme
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminPendingInvitesGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AdminInviteResultView>>;
    public apiAdminPendingInvitesGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AdminInviteResultView>>>;
    public apiAdminPendingInvitesGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AdminInviteResultView>>>;
    public apiAdminPendingInvitesGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AdminInviteResultView>>('get',`${this.basePath}/api/Admin/PendingInvites`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Register a new Admin
     * 
     * @param body 
     * @param scheme 
     * @param captcha 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminPost(body?: AdminInsertView, scheme?: string, captcha?: string, observe?: 'body', reportProgress?: boolean): Observable<TokenView>;
    public apiAdminPost(body?: AdminInsertView, scheme?: string, captcha?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TokenView>>;
    public apiAdminPost(body?: AdminInsertView, scheme?: string, captcha?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TokenView>>;
    public apiAdminPost(body?: AdminInsertView, scheme?: string, captcha?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (captcha !== undefined && captcha !== null) {
            queryParameters = queryParameters.set('captcha', <any>captcha);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TokenView>('post',`${this.basePath}/api/Admin`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify an Admin
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminPut(body?: AdminPutView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminPut(body?: AdminPutView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminPut(body?: AdminPutView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminPut(body?: AdminPutView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Admin`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove an Admin from the scheme
     * 
     * @param adminId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminRemoveFromSchemeAdminIdDelete(adminId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminRemoveFromSchemeAdminIdDelete(adminId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminRemoveFromSchemeAdminIdDelete(adminId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminRemoveFromSchemeAdminIdDelete(adminId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (adminId === null || adminId === undefined) {
            throw new Error('Required parameter adminId was null or undefined when calling apiAdminRemoveFromSchemeAdminIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Admin/RemoveFromScheme/${encodeURIComponent(String(adminId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get admin roles
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminRolesListGet(observe?: 'body', reportProgress?: boolean): Observable<Array<AdminRoleResultView>>;
    public apiAdminRolesListGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AdminRoleResultView>>>;
    public apiAdminRolesListGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AdminRoleResultView>>>;
    public apiAdminRolesListGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AdminRoleResultView>>('get',`${this.basePath}/api/Admin/RolesList`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminSetupTwoFactorAuthGet(email?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminSetupTwoFactorAuthGet(email?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminSetupTwoFactorAuthGet(email?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminSetupTwoFactorAuthGet(email?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Admin/SetupTwoFactorAuth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminValidateTwoFactorAuthPost(body?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminValidateTwoFactorAuthPost(body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminValidateTwoFactorAuthPost(body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminValidateTwoFactorAuthPost(body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Admin/ValidateTwoFactorAuth`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint for email verification
     * 
     * @param aId 
     * @param verificationCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminVerifyEmailGet(aId?: string, verificationCode?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminVerifyEmailGet(aId?: string, verificationCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminVerifyEmailGet(aId?: string, verificationCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminVerifyEmailGet(aId?: string, verificationCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (aId !== undefined && aId !== null) {
            queryParameters = queryParameters.set('aId', <any>aId);
        }
        if (verificationCode !== undefined && verificationCode !== null) {
            queryParameters = queryParameters.set('verificationCode', <any>verificationCode);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Admin/VerifyEmail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify the invitation
     * 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminVerifyInviteCodeGet(code: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminVerifyInviteCodeGet(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminVerifyInviteCodeGet(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminVerifyInviteCodeGet(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling apiAdminVerifyInviteCodeGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Admin/verifyInvite/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
