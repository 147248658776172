<mat-card class="mini-entity">
  <mat-card-header>
    <mat-card-subtitle *ngIf="preTitle" class="mb-1">{{preTitle}}</mat-card-subtitle>
    <mat-card-title>
      {{customer.firstName ? customer.firstName : 'N/A'}}&nbsp;{{customer.lastName ? customer.lastName : ''}}
    </mat-card-title>
    <mat-card-subtitle>{{balance?.monetaryValue |
      currency:sessionService.schemeCurrency:'symbol':'1.2-2'}}</mat-card-subtitle>

    <div class="top-right-action">
      <a mat-icon-button matTooltip="Open" [routerLink]="['/customers/details', customer.id]"
        (click)="onRedirect.emit()" *ngIf="!disabled">
        <mat-icon>launch</mat-icon>
      </a>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="d-flex align-items-center mb-2">
      <mat-icon class="mr-1">email</mat-icon>
      <span>{{customer.email ? customer.email : 'N/A'}}</span>
    </div>
    <div class="d-flex align-items-center mb-2">
      <mat-icon class="mr-1">call</mat-icon>
      <span>{{customer.areaCode ? customer.areaCode : ''}}&nbsp;{{customer.mobileNumber ? customer.mobileNumber :
        'N/A'}}</span>
    </div>
    <div class="d-flex align-items-center mb-2">
      <mat-icon class="mr-1">qr_code_2</mat-icon>
      <span>{{customer.barCode}}</span>
    </div>
  </mat-card-content>
</mat-card>
