<mat-toolbar color="warn" class="cta cta-light-warning" *ngIf="sessionService?.schemeHealth?.billingDetailsMissing">
  <mat-icon class="mr-2 d-none d-sm-flex">report</mat-icon>
  <div class="d-flex flex-column flex-md-row justify-content-between flex-grow-1">
    <span>Missing Billing Details</span>
    <span class="small d-none d-sm-flex">Your billing details are require for your invoice and VAT.</span>
  </div>
  <a [routerLink]="['/settings/subscription/billing']" mat-flat-button class="ml-2"
    *ngIf="sessionService.agentIsOwner">Resolve</a>
</mat-toolbar>

<mat-toolbar color="warn" class="cta cta-medium-warning" *ngIf="sessionService?.schemeHealth?.paymentMethodMissing">
  <mat-icon class="mr-2 d-none d-sm-flex">report</mat-icon>
  <div class="d-flex flex-column flex-md-row justify-content-between flex-grow-1">
    <span>Missing Payment Method</span>
    <span class="small d-none d-sm-flex">A payment method is required to enable customer communication.</span>
  </div>
  <a [routerLink]="['/settings/subscription/payments']" mat-flat-button class="ml-2"
    *ngIf="sessionService.agentIsOwner">Resolve</a>
</mat-toolbar>

<mat-toolbar color="warn" class="cta" *ngIf="sessionService?.schemeHealth?.pendingInvoice">
  <mat-icon class="mr-2 d-none d-sm-flex">report</mat-icon>
  <div class="d-flex flex-column flex-md-row justify-content-between flex-grow-1">
    <span>Unpaid Invoice</span>
    <span class="small d-none d-sm-flex">If an invoice is not paid your scheme will get suspended.</span>
  </div>
  <a [routerLink]="['/settings/subscription/invoices']" mat-flat-button class="ml-2"
    *ngIf="sessionService.agentIsOwner">Resolve</a>
</mat-toolbar>
