import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LevelResultView, OutletResultView, PointsSettingResultView, SchemeHealthView, SchemeResultView, SchemeResultViewWithCurrencySymbol, SchemeSetupProgressView } from '../swagger';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
var CryptoTS = require("crypto-ts");

export class NestedLevels {
  tierName: string;
  levels: LevelResultView[];
}

export class NestedOutlets {
  franchiseName: string;
  outlets: OutletResultView[];
}

export class Coordinates {
  lat: number;
  lng: number;
}

@Injectable()
export class SessionService {
  constructor(private router: Router, private snackBar: MatSnackBar, private jwtHelperService: JwtHelperService) { }

  // token
  public set token(token: string) {
    localStorage.setItem('token', token);
  }

  public get token() {
    return localStorage.getItem('token');
  }

  // super
  public set superAdmin(superAdmin: string) {
    localStorage.setItem('superAdmin', this.encrypt(superAdmin));
  }

  public get superAdmin() {
    const res = localStorage.getItem('superAdmin') ? this.decrypt(localStorage.getItem('superAdmin')) : 'false';
    return JSON.parse(res);
  }

  // agent
  public set agent(agent: any) {
    localStorage.setItem('agentEmail', agent.userName);
    localStorage.setItem('agentId', agent.userId);
  }

  public get agentEmail() {
    return localStorage.getItem('agentEmail');
  }

  public get agentId() {
    return localStorage.getItem('agentId');
  }

  public set agentRole(role: string) {
    localStorage.setItem('agentRole', role);
  }

  public get agentRole() {
    return localStorage.getItem('agentRole');
  }

  // scheme
  public set scheme(scheme: SchemeResultViewWithCurrencySymbol) {
    localStorage.setItem('schemeName', scheme.name);
    localStorage.setItem('schemeId', scheme.id);
    localStorage.setItem('schemeAccountId', String(scheme.accountId));
    localStorage.setItem('schemeCurrency', scheme.currency);
    localStorage.setItem('schemeCurrencySymbol', scheme.currencySymbol);
    localStorage.setItem('schemeInLoyale', String(scheme.inLoyale));
    localStorage.setItem('schemePaymentType', String(scheme.paymentType));

    if (scheme.imageUrl) {
      localStorage.setItem('schemeImageUrl', scheme.imageUrl);
    } else {
      localStorage.removeItem('schemeImageUrl');
    }

    localStorage.setItem('schemeColor', scheme.color);
    localStorage.setItem('schemePlan', JSON.stringify(scheme.plan));
    localStorage.setItem('schemeOffline', String(scheme.hidden));
    localStorage.setItem('schemeStatus', this.encrypt(scheme.status.toString()));
    localStorage.setItem('schemeTwofa', String(scheme.requireTwoFactorAuth));
    localStorage.setItem('schemeOrigin', String(scheme.origin));
    localStorage.setItem('schemeTimezone', scheme.timezone);
    localStorage.setItem('schemeSetupComplete', String(scheme.setupCompleted));
    localStorage.setItem('schemeFeatures', JSON.stringify(scheme.features));
    localStorage.setItem('schemeRedemptionRate', JSON.stringify(scheme.pointRedemptionPerCurrency));
  }

  public get schemeName() {
    return localStorage.getItem('schemeName');
  }

  public get schemeId() {
    return localStorage.getItem('schemeId');
  }

  public get schemeAccountId() {
    return +localStorage.getItem('schemeAccountId');
  }

  public get schemeCurrency() {
    return localStorage.getItem('schemeCurrency');
  }

  public get schemeCurrencySymbol() {
    return localStorage.getItem('schemeCurrencySymbol');
  }

  public get schemePaymentType() {
    return +localStorage.getItem('schemePaymentType');
  }

  public set schemeImageUrl(imageUrl: string) {
    localStorage.setItem('schemeImageUrl', imageUrl);
  }

  public get schemeImageUrl() {
    return localStorage.getItem('schemeImageUrl');
  }

  public get schemeColor() {
    return localStorage.getItem('schemeColor');
  }

  public get schemePlan() {
    return JSON.parse(localStorage.getItem('schemePlan'));
  }

  public get schemeOffline() {
    return JSON.parse(localStorage.getItem('schemeOffline'));
  }

  public set schemeStatus(status: number) {
    localStorage.setItem('schemeStatus', this.encrypt(status.toString()));
  }

  public get schemeStatus() {
    let res = localStorage.getItem('schemeStatus') ? this.decrypt(localStorage.getItem('schemeStatus')) : '0';
    return +res;
  }

  public get schemeTwofa() {
    return JSON.parse(localStorage.getItem('schemeTwofa'));
  }

  public get schemeOrigin() {
    return +localStorage.getItem('schemeOrigin');
  }

  public get schemeTimezone() {
    return localStorage.getItem('schemeTimezone');
  }

  public get schemeSetupComplete() {
    return JSON.parse(localStorage.getItem('schemeSetupComplete'));
  }

  public get schemeRedemptionRate() {
    if (localStorage.getItem('schemeRedemptionRate')) {
      return +localStorage.getItem('schemeRedemptionRate');
    } else {
      this.snackBar.open('Fetching missing data');
      this.router.navigate(['/load']);
    }
  }

  public set schemeFeatures(schemeFeatures: any) {
    if (localStorage.getItem('schemeFeatures')) {
      localStorage.setItem('schemeFeatures', JSON.stringify(schemeFeatures));
    } else {
      this.snackBar.open('Fetching missing data');
      this.router.navigate(['/load']);
    }
  }

  public get schemeFeatures() {
    return JSON.parse(localStorage.getItem('schemeFeatures'));
  }

  public get schemeInLoyale() {
    return JSON.parse(localStorage.getItem('schemeInLoyale'));
  }

  public set schemeHealth(schemeHealth: SchemeHealthView) {
    localStorage.setItem('schemeHealth', JSON.stringify(schemeHealth));
  }

  public get schemeHealth() {
    return JSON.parse(localStorage.getItem('schemeHealth'));
  }

  public set schemeSetup(schemeSetup: SchemeSetupProgressView) {
    localStorage.setItem('schemeSetup', JSON.stringify(schemeSetup));
  }

  public get schemeSetup() {
    return JSON.parse(localStorage.getItem('schemeSetup'));
  }

  public set pointsSettings(pointsSettings: PointsSettingResultView) {
    localStorage.setItem('pointsSettings', JSON.stringify(pointsSettings));
  }

  public get pointsSettings() {
    return JSON.parse(localStorage.getItem('pointsSettings'));
  }

  // other
  public set sidenav(sidenav: any) {
    localStorage.setItem('sidenav', JSON.stringify(sidenav));
  }

  public get sidenav() {
    return JSON.parse(localStorage.getItem('sidenav'));
  }

  public get agentIsAdminPlus() {
    let isAdminPlus = false;
    const agentRole = localStorage.getItem('agentRole');
    if (agentRole == 'Admin' || agentRole == 'Owner') {
      isAdminPlus = true;
    }

    return isAdminPlus;
  }

  public get agentIsOwner() {
    let isOwner = false;
    const agentRole = localStorage.getItem('agentRole');
    if (agentRole == 'Owner') {
      isOwner = true;
    }

    return isOwner;
  }

  removeToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('superAdmin');
  }

  removeAgent() {
    localStorage.removeItem('agentId');
    localStorage.removeItem('agentEmail');
    localStorage.removeItem('agentRole');
  }

  removeScheme() {
    localStorage.removeItem('schemeName');
    localStorage.removeItem('schemeId');
    localStorage.removeItem('schemeCurrency');
    localStorage.removeItem('schemeInLoyale');
    localStorage.removeItem('schemePaymentType');
    localStorage.removeItem('schemeImageUrl');
    localStorage.removeItem('schemeColor');
    localStorage.removeItem('schemePlan');
    localStorage.removeItem('schemeOffline');
    localStorage.removeItem('schemeStatus');
    localStorage.removeItem('schemeTwofa');
    localStorage.removeItem('schemeOrigin');
    localStorage.removeItem('schemeTimezone');
    localStorage.removeItem('schemeSetupComplete');
    localStorage.removeItem('schemeFeatures');
    localStorage.removeItem('schemeHealth');
    localStorage.removeItem('schemeSetup');
  }

  removeJoin() {
    localStorage.removeItem('joinToken');
    localStorage.removeItem('joinAgent');
    localStorage.removeItem('joinFlow');
    localStorage.removeItem('joinSchemeId');
  }

  signOut() {
    this.removeToken();
    this.removeAgent();
    this.removeScheme();
    this.removeJoin();
    this.router.navigate(['/auth']);
  }

  tokenValid() {
    return !this.jwtHelperService.isTokenExpired(localStorage.getItem('token'));
  }

  encrypt(value: string) {
    const encryptedValue = CryptoTS.AES.encrypt(environment.storageDecryptKey + '_' + value, environment.storageEncryptKey);
    return encryptedValue;
  }

  decrypt(encryptedValue: string) {
    const decryptedValue = (CryptoTS.AES.decrypt(encryptedValue, environment.storageEncryptKey)).toString(CryptoTS.enc.Utf8);
    const decryptedValueSplit = decryptedValue.split('_');

    if (decryptedValueSplit[0] == environment.storageDecryptKey) {
      return decryptedValueSplit[1];
    } else {
      return undefined
    }
  }

  public set introCollapse(collapse: boolean) {
    sessionStorage.setItem('introCollapse', JSON.stringify(collapse));
  }

  public get introCollapse() {
    if (sessionStorage.getItem('introCollapse')) {
      return JSON.parse(sessionStorage.getItem('introCollapse'));
    } else {
      return false
    }
  }
}
