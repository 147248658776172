import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loyale-stats-card',
  templateUrl: './stats-card.component.html',
  styleUrls: ['./stats-card.component.scss']
})
export class StatsCardComponent implements OnInit {
  @Input() icon: 'star';
  @Input() tooltip: '';
  @Input() value: '';
  @Input() secondaryValue: null;
  @Input() label: '';

  constructor() { }

  ngOnInit(): void {
  }

}
