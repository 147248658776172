import { AfterViewInit, Component, ElementRef, HostListener, Inject, inject, OnInit, ViewChild } from '@angular/core';
import { ThemeService } from './services/theme.service';
import { SessionService } from './services/session.service';
import { environment } from 'src/environments/environment';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { CommonService } from './services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { TourService } from 'ngx-ui-tour-md-menu';
import { DOCUMENT } from '@angular/common';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'loyale-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  mobileMode = false;
  sidenavMode = 'side';
  sidenavOpen = true;

  env = environment;

  superClickCounter = 0;

  private readonly tourService = inject(TourService);

  @ViewChild('appSidenav') sidenav: MatSidenav;

  // on resize, if below 600px width, enable mobile layout
  @HostListener('window:resize', ['$event']) onResize(event) {
    let windowWidth = event.target.innerWidth;
    if (!this.mobileMode && windowWidth <= 600) {
      this.setLayout(true);
    } else if (this.mobileMode && windowWidth >= 601) {
      this.setLayout(false);
    }
  }

  constructor(
    public sessionService: SessionService,
    private translateService: TranslateService,
    public commonService: CommonService,
    public themeService: ThemeService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    //set translation to browser navigator language
    this.translateService.use(navigator.language);

    //set theme
    //this.themeService.theme = 'violet-orange-theme';
    this.themeService.theme = 'purple-orange-theme';

    //if window width below 600px, set mobile layout
    if (window.innerWidth <= 600) {
      this.setLayout(true);
    }

    this.commonService.loadIntercom();
    this.commonService.startUpdateCheck();
    this.commonService.startSessionCheck();

    if (this.sessionService.tokenValid() && this.sessionService.schemeId) {
      this.commonService.startHealthCheck();
      this.commonService.startSetupCheck();
    }
  }

  setLayout(mobile: boolean) {
    if (mobile) {
      this.sidenavMode = 'over';
      this.sidenavOpen = false;
      this.mobileMode = true;
    } else {
      this.sidenavMode = 'side';
      this.sidenavOpen = true;
      this.mobileMode = false;
    }
  }

  onSuperClick() {
    if (this.superClickCounter == 4) {
      this.router.navigate(['/auth/super']);
    } else {
      this.superClickCounter = this.superClickCounter + 1;
    }
  }

  onAppContentScroll(event) {
    const scrollTop = event.target.scrollTop;
    const introCollapsed = this.sessionService.introCollapse;

    if (!introCollapsed && scrollTop > 70) {
      this.sessionService.introCollapse = true;
    } else if (introCollapsed && scrollTop < 2) {
      this.sessionService.introCollapse = false;
    }
  }
}
