import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SchemeService } from './../../swagger/api/scheme.service';
import { SessionService } from './../../services/session.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadDialogComponent } from '../load-dialog/load-dialog.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'loyale-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.scss']
})
export class LoadComponent implements OnInit, OnDestroy {
  dialogRef = null;

  private destroy$ = new Subject<void>();

  constructor(public sessionService: SessionService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router, private schemeService: SchemeService, private commonService: CommonService) { }

  ngOnInit() {
    console.info('Load ' + this.sessionService.schemeId);
    this.getScheme();
    this.dialogRef = this.dialog.open(LoadDialogComponent, { disableClose: true });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getScheme() {
    this.schemeService.apiSchemeIdGet(this.sessionService.schemeId).subscribe(res => {
      this.sessionService.scheme = res;

      if (!res.imageUrl) {
        console.info('Scheme missing its image, random image generated');
        let gradientColorLeft = this.commonService.getRandomColor();
        let gradientColorRight = this.commonService.getRandomColorShade(gradientColorLeft);

        const placeholder = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
          viewBox="0 0 512 512">
          <defs>
            <style>
              .st0 {
                fill: url(#linear-gradient1);
              }

              .st1 {
                fill: url(#linear-gradient);
              }

              .st2,
              .st3 {
                fill: #fff;
              }

              .st3 {
                isolation: isolate;
                opacity: .4;
              }
            </style>
            <linearGradient id="linear-gradient" class="background" x1="0" y1="256" x2="512" y2="256"
              gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="`+ gradientColorLeft + `" />
              <stop offset="1" stop-color="`+ gradientColorRight + `" />
            </linearGradient>
            <linearGradient id="linear-gradient1" x1="174.5" y1="176.5" x2="337.5" y2="339.5"
              gradientTransform="translate(0 514) scale(1 -1)" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#fff" stop-opacity=".8" />
              <stop offset="1" stop-color="#fff" stop-opacity=".6" />
            </linearGradient>
          </defs>
          <g id="Layer_1-2">
            <rect class="st1" width="512" height="512" />
            <path class="st3"
              d="M371.2,208.3h-115.2c-13.1,0-25,5.3-33.6,13.8l-81.6,81.6v-161.4c0-16.3,13.2-29.6,29.6-29.6h85.7c12.7,0,24.8,5,33.8,14l81.5,81.5h0Z" />
            <path class="st2"
              d="M371.2,208.3v115.2c0,12.7-5,24.8-14,33.8l-60.6,60.6c-11.5,11.5-30.2,11.5-41.8,0l-114.1-114.1h115.2c13.2,0,25.1-5.3,33.8-14l81.5-81.5h0Z" />
            <path class="st0"
              d="M371.2,208.3h0l-81.5,81.5c-8.6,8.6-20.6,14-33.8,14h-115.2l81.6-81.6c8.6-8.6,20.5-13.8,33.6-13.8h115.2,0Z" />
          </g>
          </svg>`;

        this.commonService.getPngBlobFromSvg(placeholder, 512, 512).then((blob) => {
          this.schemeService.apiSchemeChangeImagePostForm(blob, res.id).subscribe(res2 => {
            this.sessionService.schemeImageUrl = res2.imageUrl;
          });
        })
      }

      this.commonService.loadIntercom();
      this.commonService.startUpdateCheck();
      this.commonService.startSessionCheck();
      this.commonService.startHealthCheck();
      this.commonService.startSetupCheck();
      this.redirectApplication();
    });
  }

  redirectApplication() {
    let destination = sessionStorage.getItem('destination');

    //redirect application only after health and status checks have finished running
    const checkingSub = this.commonService.onChangeViewCheckDone.pipe(takeUntil(this.destroy$)).subscribe((checksNumber: number) => {
      this.dialogRef.close();
      if (checksNumber >= 2) {
        if (destination) {
          sessionStorage.removeItem('destination');
          this.router.navigateByUrl(destination);
          this.destroy$.next();
          this.destroy$.complete();
        } else {
          this.router.navigate(['/dashboard'], { queryParamsHandling: 'merge' });
          this.destroy$.next();
          this.destroy$.complete();
        }

      }
    });
  }
}
