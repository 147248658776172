<div class= "sticky-wrapper mb-4">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <div class="text-grey">Preview</div>
  </div>

  <div class="preview-coupon">
    <div class="list mb-3">
      <div class="d-flex flex-row align-items-center">
        <div class="image-wrapper mr-3">
          <img [src]="coupon.imageUrl ? coupon.imageUrl : './assets/preview/placeholder.png'" class="w-100 br-5">
        </div>
        <div class="text-wrapper d-flex flex-column">
          <div class="title">{{coupon.name ? coupon.name : 'Name'}}</div>
          <div class="valid text-grey">Valid till {{dummyDate | date:'dd/MM/yyyy'}}</div>
        </div>
      </div>
    </div>

    <div class="detail">
      <div class="qrcode-wrapper text-center mb-3">
        <img [src]="dummyQrCodeUrl" class="w-100">
        <div class="barcode">{{dummyBarCode}}</div>
      </div>
      <div class="text-wrapper">
        <div class="valid text-grey">Valid till {{dummyDate | date:'dd/MM/yyyy'}}</div>
        <div class="title">{{coupon.name ? coupon.name : 'Name'}}</div>
        <div class="description text-grey">{{coupon.description ? coupon.description : 'Description'}}</div>
        <hr />
        <div class="usage d-flex align-items-center mb-3">
          <mat-icon>rotate_left</mat-icon>
          <span class="hint ml-1" *ngIf="coupon.initialNumberOfUses != -1">{{coupon.initialNumberOfUses ?
            coupon.initialNumberOfUses : '1'}} Time Use</span>
          <span class="hint ml-1" *ngIf="coupon.initialNumberOfUses == -1">Infinite Use</span>
        </div>
        <hr />
        <div class="terms text-grey" [innerText]="coupon.terms ? coupon.terms : 'Terms & Conditions'"></div>
      </div>
    </div>
  </div>
</div>
