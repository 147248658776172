<h1 mat-dialog-title>Switch Scheme</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Search</mat-label>
    <input cdkFocusInitial matInput name="schemeFilter" #schemeFilter="ngModel" [(ngModel)]="filter"
      (ngModelChange)="onFiltersChange()" type="search" autocomplete="off">
  </mat-form-field>

  <mat-form-field class="ml-3">
    <mat-label>Plan</mat-label>
    <mat-select name="schemePlan" #schemePlan="ngModel" [(ngModel)]="plan" (ngModelChange)="onFiltersChange()">
      <mat-option [value]="null">All</mat-option>
      <mat-option *ngFor="let plan of plans" [value]="plan.id">
        {{plan.name}}
        <ng-container *ngIf="sessionService.superAdmin">
          <span class="mx-2 super-item">{{plan.type}}</span>&nbsp;
          <span class="mx-2 super-item" *ngIf="plan.createdDate >= '2025-01-01'">NEW</span>
        </ng-container>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="ml-3">
    <mat-label>Status</mat-label>
    <mat-select name="schemeStatus" #schemeStatus="ngModel" [(ngModel)]="status" (ngModelChange)="onFiltersChange()">
      <mat-option [value]="null">All</mat-option>
      <mat-option [value]="0">Trial</mat-option>
      <mat-option [value]="1">Active</mat-option>
      <mat-option [value]="3">Provisional</mat-option>
      <mat-option [value]="4">Suspended</mat-option>
      <mat-option [value]="2">Closed</mat-option>
      <mat-option [value]="5">Demo</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="d-flex align-items-center justify-content-center vh-25" *ngIf="awaitingGet">
    <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
  </div>

  <ng-container *ngIf="!awaitingGet">
    <div class="table-wrapper">
      <table mat-table [dataSource]="filteredSchemes" class="w-100">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let scheme">
            <button mat-button (click)="onLoadScheme(scheme)" [disabled]="awaitingPost">
              <img [src]="scheme.imageUrl == null ? '../assets/placeholder.svg' : scheme.imageUrl" width="24"
                class="br-5 mr-2">
              {{scheme.name}}
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="accountId">
          <th mat-header-cell *matHeaderCellDef> Account ID </th>
          <td mat-cell *matCellDef="let scheme">
            {{scheme.accountId}}
          </td>
        </ng-container>

        <ng-container matColumnDef="plan">
          <th mat-header-cell *matHeaderCellDef> Plan </th>
          <td mat-cell *matCellDef="let scheme">
            <div class="d-flex align-items-center">
              <span>{{scheme.plan ? scheme.plan.name : 'N/A'}}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let scheme">
            <div class="d-flex align-items-center">
              <mat-icon *ngIf="scheme.status == 0" matTooltip="Trial">watch_later</mat-icon>
              <mat-icon *ngIf="scheme.status == 1" matTooltip="Active">check_circle</mat-icon>
              <mat-icon *ngIf="scheme.status == 2" matTooltip="Closed">cancel</mat-icon>
              <mat-icon *ngIf="scheme.status == 3" matTooltip="Provisional">pending</mat-icon>
              <mat-icon *ngIf="scheme.status == 4" matTooltip="Suspended">error</mat-icon>
              <mat-icon *ngIf="scheme.status == 5" matTooltip="Demo">science</mat-icon>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="other">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let scheme" class="text-right">
            <div class="super-item" *ngIf="sessionService.superAdmin">
              {{scheme.id}}

              <!-- <button type="button" mat-icon-button color="warn" matTooltip="Delete" (click)="onDeleteScheme(scheme)"
                [disabled]="awaitingPost">
                <mat-icon>delete</mat-icon>
              </button> -->
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row-hover"></tr>
      </table>
    </div>
  </ng-container>
</div>
