<div class= "sticky-wrapper mb-4">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <div class="text-grey">Preview</div>
  </div>

  <div class="shopify-preview" [ngClass]="config.position">
    <div class="frame-wrapper">
      <div class="frame d-none">
        <div class="header" [ngStyle]="{'background-color': config.primary}">
          <div class="layer"></div>
          <div class="welcome mt-4">
            <h2>Hello, John</h2>
            <div class="d-flex flex-row align-items-end">
              <h1>562</h1>
              <h2>&nbsp;pts</h2>
            </div>
          </div>
        </div>
        <div class="main push">
          <div class="float">
            <div class="item-link d-flex flex-row align-items-center cursor-pointer" tabindex="0">
              <div class="icon-wrapper" [ngStyle]="{'color': config.accent}">
                <div class="layer" [ngStyle]="{'background-color': config.accent}">
                </div><i class="loyale-icon-gift"></i>
              </div>
              <div class="text-wrapper d-flex flex-column flex-grow-1 text-left">
                <h5>Your rewards</h5>
                <p>2 Unused rewards</p>
              </div>
              <div class="more-wrapper"><i class="icon-right"></i></div>
            </div>
          </div>
          <div class="float">
            <h3 class="mb-4">Points</h3>
            <div class="item-link d-flex flex-row align-items-center cursor-pointer" tabindex="0">
              <div class="icon-wrapper" [ngStyle]="{'color': config.accent}">
                <div class="layer" [ngStyle]="{'background-color': config.accent}"></div><i
                  class="loyale-icon-coins"></i>
              </div>
              <div class="text-wrapper d-flex flex-column flex-grow-1 text-left">
                <h5>Ways to earn points</h5>
                <p>Learn more</p>
              </div>
              <div class="more-wrapper"><i class="icon-right"></i></div>
            </div>
            <div class="item-link d-flex flex-row align-items-center cursor-pointer" tabindex="0">
              <div class="icon-wrapper" [ngStyle]="{'color': config.accent}">
                <div class="layer" [ngStyle]="{'background-color': config.accent}"></div><i
                  class="loyale-icon-history"></i>
              </div>
              <div class="text-wrapper d-flex flex-column flex-grow-1 text-left">
                <h5>History</h5>
                <p>Learn more</p>
              </div>
              <div class="more-wrapper"><i class="icon-right"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-wrapper">
      <div class="loyale-toggle" [ngStyle]="{'background-color': config.primary}">
        <i class="loyale-icon" [ngClass]="'launcher-'+config.icon"
          *ngIf="config.launcher == 0 || config.launcher == 1"></i>
        <span class="loyale-label" [ngClass]="{'spaced': config.launcher == 0}"
          *ngIf="config.launcher == 0 || config.launcher == 2">{{config.label}}</span>
      </div>
    </div>
  </div>
</div>
