<mat-toolbar color="warn" class="cta cta-medium-warning" *ngIf="sessionService.schemeStatus == 3">
  <mat-icon class="mr-2 d-none d-sm-flex">dangerous</mat-icon>
  <div class="d-flex flex-column flex-md-row justify-content-between flex-grow-1">
    <span>Scheme Provisional</span>
    <span class="small d-none d-sm-flex">Your scheme is provisional, kindly contact your administrator.</span>
  </div>
  <button type="button" mat-flat-button class="ml-2" *ngIf="sessionService.agentIsOwner" (click)="onContactUs()">Contact
    Us</button>
</mat-toolbar>

<mat-toolbar color="warn" class="cta" *ngIf="sessionService.schemeStatus == 4">
  <mat-icon class="mr-2 d-none d-sm-flex">dangerous</mat-icon>
  <div class="d-flex flex-column flex-md-row justify-content-between flex-grow-1">
    <span>Scheme Suspended</span>
    <span class="small d-none d-sm-flex">Your scheme is suspended, kindly contact your administrator.</span>
  </div>
  <button type="button" mat-flat-button class="ml-2" *ngIf="sessionService.agentIsOwner" (click)="onContactUs()">Contact
    Us</button>
</mat-toolbar>

<mat-toolbar color="warn" class="cta cta-light-warning" *ngIf="sessionService.schemeStatus == 2">
  <mat-icon class="mr-2 d-none d-sm-flex">dangerous</mat-icon>
  <div class="d-flex flex-column flex-md-row justify-content-between flex-grow-1">
    <span>Scheme Closed</span>
    <span class="small d-none d-sm-flex">Your scheme is closed, kindly contact your administrator.</span>
  </div>
  <button type="button" mat-flat-button class="ml-2" *ngIf="sessionService.agentIsOwner" (click)="onContactUs()">Contact
    Us</button>
</mat-toolbar>
