<mat-card>
  <mat-card-content>
    <div class="row align-items-center">
      <div class="col-12 col-lg-5 offset-lg-1">
        <div class="py-lg-5">
          <h4>{{title}}</h4>
          <p>{{description}}</p>

          <div class="mt-5 mb-3">
            <div class="d-flex flex-row" *ngFor="let point of points">
              <div>
                <mat-icon color="accent" class="is-5">
                  check_circle
                </mat-icon>
              </div>
              <div class="d-flex flex-column ml-3">
                <h5>{{point.title}}</h5>
                <p>{{point.subtitle}}</p>
              </div>
            </div>
          </div>

          <ng-container *ngIf="isAvailable">
            <a mat-flat-button color="accent" [routerLink]="[redirectRoute]">Create Your First {{featureName}}</a>
          </ng-container>

          <ng-container *ngIf="!isAvailable">
            <h6>Available on higher plans</h6>
            <a mat-flat-button color="accent" class="mt-3" subscriptionChangePlanDialogCall>Explore Plans</a>
          </ng-container>
        </div>
      </div>
      <div class="col-12 col-lg-5">
        <img [src]="imageUrl" alt="start" class="w-100 mt-5 mt-lg-0">
      </div>
    </div>
  </mat-card-content>
</mat-card>
