<div class="d-flex align-items-center pt-3">
  <mat-icon>fingerprint</mat-icon>
  <loyale-readonly-field label="ID" [value]="data.entity.id" class="w-100 ml-3"></loyale-readonly-field>
</div>

<div class="d-flex align-items-center">
  <mat-icon>calendar_today</mat-icon>
  <loyale-readonly-field label="Created Date"
    [value]="data.entity.createdDate | amFromUtc | amLocal | amDateFormat:'DD/MM/YYYY HH:mm'"
    class="w-100 ml-3"></loyale-readonly-field>
</div>

<div class="d-flex align-items-center">
  <mat-icon>person</mat-icon>
  <loyale-readonly-field label="Created By" [value]="data.entity.createdBy" class="w-100 ml-3"></loyale-readonly-field>
</div>

<div class="d-flex align-items-center">
  <mat-icon>calendar_today</mat-icon>
  <loyale-readonly-field label="Updated Date"
    [value]="data.entity.updatedDate | amFromUtc | amLocal | amDateFormat:'DD/MM/YYYY HH:mm'"
    class="w-100 ml-3"></loyale-readonly-field>
</div>

<div class="d-flex align-items-center">
  <mat-icon>person</mat-icon>
  <loyale-readonly-field label="Updated By" [value]="data.entity.updatedBy" class="w-100 ml-3"></loyale-readonly-field>
</div>
