/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BalanceTransactionResultView } from './balanceTransactionResultView';
import { DestinationType } from './destinationType';
import { SchemeStripeLink } from './schemeStripeLink';
import { StripePayoutAttempt } from './stripePayoutAttempt';
import { StripePayoutStatus } from './stripePayoutStatus';

export interface EstimatePayoutResultView { 
    balanceTransactions?: Array<BalanceTransactionResultView>;
    id?: string;
    amount?: number;
    arrivalDate?: Date;
    currency?: string;
    description?: string;
    lastFailureMessage?: string;
    payoutId?: string;
    destination?: string;
    destinationType?: DestinationType;
    status?: StripePayoutStatus;
    schemeStripeLinkId?: string;
    schemeStripeLink?: SchemeStripeLink;
    createdBy?: string;
    updatedBy?: string;
    paymentsFromDate?: Date;
    paymentsToDate?: Date;
    stripePayoutAttempts?: Array<StripePayoutAttempt>;
    charges?: string;
    createdDate?: Date;
    updatedDate?: Date;
}