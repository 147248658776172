/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SchemeStatus } from './schemeStatus';
import { SchemeTerm } from './schemeTerm';

export interface SchemeHealthView { 
    schemeTerm?: SchemeTerm;
    schemeStatus?: SchemeStatus;
    paymentMethodMissing?: boolean;
    billingDetailsMissing?: boolean;
    pendingInvoice?: boolean;
}