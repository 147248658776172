/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GiftCardLinkedPostView { 
    purchasingCustomerId?: string;
    quantity?: number;
    value?: number;
    giftCardId?: string;
    customerId?: string;
    toName?: string;
    fromName?: string;
    message?: string;
    isEnabled?: boolean;
    comments?: string;
    favorite?: boolean;
}