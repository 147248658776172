<div class="sticky-wrapper mb-4">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <div class="text-grey">Preview</div>
  </div>

  <ng-container *ngIf="!awaitingGet">
    <div class="preview-email">
      <div class="from mb-2">
        <span class="font-weight-bold">{{settings.fromName}}</span>&nbsp;-&nbsp;<small>
          {{settings.fromEmailPrefix}}@{{settings.customDomainName ? settings.customDomainName : 'loyale.io'}}
        </small>
      </div>

      <img class="header" [src]="settings.headerImageUrl" alt="header">

      <div class="text">
        <ng-container *ngIf="message; else placeholderText">
          <div class="message" [innerHtml]="message | safeHtml"></div>
        </ng-container>
        <ng-template #placeholderText>
          <img class="w-100" src="./assets/preview/email/body.png">
        </ng-template>
        <div class="signature">{{settings.signature}}</div>
      </div>

      <img class="footer" [src]="settings.footerImageUrl" alt="footer">
    </div>
  </ng-container>
</div>
