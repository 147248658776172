<div class="d-flex align-items-center pt-3">
  <mat-icon>email</mat-icon>
  <loyale-readonly-field label="Email" [value]="data.email" class="w-100 ml-3"></loyale-readonly-field>
</div>

<div class="d-flex align-items-center">
  <mat-icon>password</mat-icon>
  <loyale-readonly-field label="Password" [value]="data.password" class="w-100 ml-3"></loyale-readonly-field>
</div>

<div class="d-flex align-items-center">
  <mat-icon>Token</mat-icon>
  <loyale-readonly-field label="Token" [value]="data.token" [textarea]="true" [textareaRows]="5"
    class="w-100 ml-3"></loyale-readonly-field>
</div>

<div class="d-flex justify-content-center">
  <small>This token is valid for 24 hours from creation</small>
</div>
