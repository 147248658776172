import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountService } from './api/account.service';
import { ActivityService } from './api/activity.service';
import { AdditionalAdminFieldsService } from './api/additionalAdminFields.service';
import { AdditionalCustomerFieldsService } from './api/additionalCustomerFields.service';
import { AdditionalSchemeFieldsService } from './api/additionalSchemeFields.service';
import { AdminService } from './api/admin.service';
import { AdminAuthEmailService } from './api/adminAuthEmail.service';
import { AdminAuthSmsService } from './api/adminAuthSms.service';
import { AlertService } from './api/alert.service';
import { AlertsLinkedService } from './api/alertsLinked.service';
import { ApiKeyService } from './api/apiKey.service';
import { ApplicationSettingsService } from './api/applicationSettings.service';
import { AuthService } from './api/auth.service';
import { BannersService } from './api/banners.service';
import { BarCodeService } from './api/barCode.service';
import { BillingService } from './api/billing.service';
import { BrandService } from './api/brand.service';
import { CacheService } from './api/cache.service';
import { CardRequestService } from './api/cardRequest.service';
import { CardTokenService } from './api/cardToken.service';
import { CategoryService } from './api/category.service';
import { ChangeLogService } from './api/changeLog.service';
import { ChargeService } from './api/charge.service';
import { ChartService } from './api/chart.service';
import { CheckoutService } from './api/checkout.service';
import { CollectionService } from './api/collection.service';
import { ContactService } from './api/contact.service';
import { CouponService } from './api/coupon.service';
import { CouponsLinkedService } from './api/couponsLinked.service';
import { CurrencyPointConversionOverrideService } from './api/currencyPointConversionOverride.service';
import { CustomerService } from './api/customer.service';
import { CustomerAuthEmailService } from './api/customerAuthEmail.service';
import { CustomerAuthSmsService } from './api/customerAuthSms.service';
import { CustomerCommentsService } from './api/customerComments.service';
import { CustomerDevicesService } from './api/customerDevices.service';
import { CustomerPolicyService } from './api/customerPolicy.service';
import { CustomerReportService } from './api/customerReport.service';
import { DashboardService } from './api/dashboard.service';
import { DemographicsService } from './api/demographics.service';
import { EmailService } from './api/email.service';
import { EmailSettingsService } from './api/emailSettings.service';
import { EmailTemplateService } from './api/emailTemplate.service';
import { EnrollmentService } from './api/enrollment.service';
import { EventTriggerService } from './api/eventTrigger.service';
import { ExportService } from './api/export.service';
import { ExternalEventTriggerService } from './api/externalEventTrigger.service';
import { ExternalPosService } from './api/externalPos.service';
import { FeedbackService } from './api/feedback.service';
import { FeedbackLinkedService } from './api/feedbackLinked.service';
import { FirebaseCredentialsService } from './api/firebaseCredentials.service';
import { FranchiseService } from './api/franchise.service';
import { GiftCardService } from './api/giftCard.service';
import { GiftCardsLinkedService } from './api/giftCardsLinked.service';
import { GroupService } from './api/group.service';
import { HangfireJobsService } from './api/hangfireJobs.service';
import { HeartbeatService } from './api/heartbeat.service';
import { HelpersService } from './api/helpers.service';
import { IProService } from './api/iPro.service';
import { IntegrationsService } from './api/integrations.service';
import { IntroService } from './api/intro.service';
import { InvoiceService } from './api/invoice.service';
import { KlaviyoService } from './api/klaviyo.service';
import { LevelService } from './api/level.service';
import { LightSpeedKService } from './api/lightSpeedK.service';
import { LightspeedRestoService } from './api/lightspeedResto.service';
import { LightspeedRetailService } from './api/lightspeedRetail.service';
import { LightspeedXService } from './api/lightspeedX.service';
import { LotteryService } from './api/lottery.service';
import { LotteryRewardService } from './api/lotteryReward.service';
import { LotteryTicketService } from './api/lotteryTicket.service';
import { MailchimpService } from './api/mailchimp.service';
import { MessageService } from './api/message.service';
import { OnboardingService } from './api/onboarding.service';
import { OutletService } from './api/outlet.service';
import { OutletCodesService } from './api/outletCodes.service';
import { PackService } from './api/pack.service';
import { PaymentMethodsService } from './api/paymentMethods.service';
import { PaymentsService } from './api/payments.service';
import { PermissionService } from './api/permission.service';
import { PingService } from './api/ping.service';
import { PlanService } from './api/plan.service';
import { PointBalanceService } from './api/pointBalance.service';
import { PointModifierService } from './api/pointModifier.service';
import { PointMultiplicationService } from './api/pointMultiplication.service';
import { PointsService } from './api/points.service';
import { PosResetService } from './api/posReset.service';
import { PostsLinkedService } from './api/postsLinked.service';
import { PreSuspensionSettingsService } from './api/preSuspensionSettings.service';
import { ProductService } from './api/product.service';
import { ProductsLinkedService } from './api/productsLinked.service';
import { ProfileService } from './api/profile.service';
import { PunchCardService } from './api/punchCard.service';
import { PunchCardsLinkedService } from './api/punchCardsLinked.service';
import { PushNotificationService } from './api/pushNotification.service';
import { PushTokenService } from './api/pushToken.service';
import { ReferralService } from './api/referral.service';
import { ReferralReportService } from './api/referralReport.service';
import { ReferralSettingsService } from './api/referralSettings.service';
import { ReportService } from './api/report.service';
import { ResetSettingsService } from './api/resetSettings.service';
import { RigService } from './api/rig.service';
import { ScheduledTaskService } from './api/scheduledTask.service';
import { SchemeService } from './api/scheme.service';
import { SchemeFeaturesService } from './api/schemeFeatures.service';
import { SchemeNotificationSettingService } from './api/schemeNotificationSetting.service';
import { SchemePolicyService } from './api/schemePolicy.service';
import { SchemeTermService } from './api/schemeTerm.service';
import { ShireburnService } from './api/shireburn.service';
import { ShopifyService } from './api/shopify.service';
import { ShopifyBasicService } from './api/shopifyBasic.service';
import { ShopifyPosService } from './api/shopifyPos.service';
import { SkuCategoryService } from './api/skuCategory.service';
import { SkuItemService } from './api/skuItem.service';
import { SkuPromotionService } from './api/skuPromotion.service';
import { SmsService } from './api/sms.service';
import { SuperAdminAuthEmailService } from './api/superAdminAuthEmail.service';
import { SuperAdminLoginTokenService } from './api/superAdminLoginToken.service';
import { SuspensionSettingsService } from './api/suspensionSettings.service';
import { SystemSettingsService } from './api/systemSettings.service';
import { SystemStatsService } from './api/systemStats.service';
import { TagService } from './api/tag.service';
import { TagsLinkedService } from './api/tagsLinked.service';
import { TaskActionService } from './api/taskAction.service';
import { TempReportsService } from './api/tempReports.service';
import { TerminationSettingsService } from './api/terminationSettings.service';
import { TestService } from './api/test.service';
import { TierService } from './api/tier.service';
import { TimeTriggerService } from './api/timeTrigger.service';
import { TopUpService } from './api/topUp.service';
import { TransactionService } from './api/transaction.service';
import { TransactionReportService } from './api/transactionReport.service';
import { TriggerBlueprintService } from './api/triggerBlueprint.service';
import { TwilioService } from './api/twilio.service';
import { UploadService } from './api/upload.service';
import { VerificationService } from './api/verification.service';
import { VivaWalletService } from './api/vivaWallet.service';
import { WalletService } from './api/wallet.service';
import { WalletPushTokenService } from './api/walletPushToken.service';
import { WebhookService } from './api/webhook.service';
import { WebhookLogService } from './api/webhookLog.service';
import { WelcomeSettingsService } from './api/welcomeSettings.service';
import { WhiteLabelSettingsService } from './api/whiteLabelSettings.service';
import { WooCommerceService } from './api/wooCommerce.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    AccountService,
    ActivityService,
    AdditionalAdminFieldsService,
    AdditionalCustomerFieldsService,
    AdditionalSchemeFieldsService,
    AdminService,
    AdminAuthEmailService,
    AdminAuthSmsService,
    AlertService,
    AlertsLinkedService,
    ApiKeyService,
    ApplicationSettingsService,
    AuthService,
    BannersService,
    BarCodeService,
    BillingService,
    BrandService,
    CacheService,
    CardRequestService,
    CardTokenService,
    CategoryService,
    ChangeLogService,
    ChargeService,
    ChartService,
    CheckoutService,
    CollectionService,
    ContactService,
    CouponService,
    CouponsLinkedService,
    CurrencyPointConversionOverrideService,
    CustomerService,
    CustomerAuthEmailService,
    CustomerAuthSmsService,
    CustomerCommentsService,
    CustomerDevicesService,
    CustomerPolicyService,
    CustomerReportService,
    DashboardService,
    DemographicsService,
    EmailService,
    EmailSettingsService,
    EmailTemplateService,
    EnrollmentService,
    EventTriggerService,
    ExportService,
    ExternalEventTriggerService,
    ExternalPosService,
    FeedbackService,
    FeedbackLinkedService,
    FirebaseCredentialsService,
    FranchiseService,
    GiftCardService,
    GiftCardsLinkedService,
    GroupService,
    HangfireJobsService,
    HeartbeatService,
    HelpersService,
    IProService,
    IntegrationsService,
    IntroService,
    InvoiceService,
    KlaviyoService,
    LevelService,
    LightSpeedKService,
    LightspeedRestoService,
    LightspeedRetailService,
    LightspeedXService,
    LotteryService,
    LotteryRewardService,
    LotteryTicketService,
    MailchimpService,
    MessageService,
    OnboardingService,
    OutletService,
    OutletCodesService,
    PackService,
    PaymentMethodsService,
    PaymentsService,
    PermissionService,
    PingService,
    PlanService,
    PointBalanceService,
    PointModifierService,
    PointMultiplicationService,
    PointsService,
    PosResetService,
    PostsLinkedService,
    PreSuspensionSettingsService,
    ProductService,
    ProductsLinkedService,
    ProfileService,
    PunchCardService,
    PunchCardsLinkedService,
    PushNotificationService,
    PushTokenService,
    ReferralService,
    ReferralReportService,
    ReferralSettingsService,
    ReportService,
    ResetSettingsService,
    RigService,
    ScheduledTaskService,
    SchemeService,
    SchemeFeaturesService,
    SchemeNotificationSettingService,
    SchemePolicyService,
    SchemeTermService,
    ShireburnService,
    ShopifyService,
    ShopifyBasicService,
    ShopifyPosService,
    SkuCategoryService,
    SkuItemService,
    SkuPromotionService,
    SmsService,
    SuperAdminAuthEmailService,
    SuperAdminLoginTokenService,
    SuspensionSettingsService,
    SystemSettingsService,
    SystemStatsService,
    TagService,
    TagsLinkedService,
    TaskActionService,
    TempReportsService,
    TerminationSettingsService,
    TestService,
    TierService,
    TimeTriggerService,
    TopUpService,
    TransactionService,
    TransactionReportService,
    TriggerBlueprintService,
    TwilioService,
    UploadService,
    VerificationService,
    VivaWalletService,
    WalletService,
    WalletPushTokenService,
    WebhookService,
    WebhookLogService,
    WelcomeSettingsService,
    WhiteLabelSettingsService,
    WooCommerceService]
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): any {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
        'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
