<div class= "sticky-wrapper mb-4">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <div class="text-grey">Preview</div>
  </div>

  <div class="preview-post">
    <div class="list mb-3">
      <div class="d-flex flex-row align-items-center">
        <div class="image-wrapper mr-3">
          <img [src]="alert?.thumbnailUrl ? alert.thumbnailUrl : './assets/preview/placeholder.png'" class="w-100 br-5">
        </div>
        <div class="text-wrapper d-flex flex-column">
          <div class="title">{{alert?.title ? alert.title : 'Title'}}</div>
          <div class="publish-date text-grey">{{dummyDate | date:'dd/MM/yyyy'}}</div>
        </div>
      </div>
    </div>

    <div class="detail">
      <div class="d-flex flex-column">
        <div class="image-wrapper">
          <img [src]="alert?.imageUrl ? alert.imageUrl : './assets/preview/placeholder.png'" class="w-100">
        </div>
        <div class="text-wrapper">
          <div class="publish-date text-grey">{{dummyDate | date:'dd/MM/yyyy'}}</div>
          <div class="title">{{alert?.title ? alert.title : 'Title'}}</div>
          <div class="sub-title text-grey">{{alert?.subTitle ? alert.subTitle : 'Sub Title'}}</div>
          <div class="text">{{alert?.text ? alert.text : 'Message'}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
