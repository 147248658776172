/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GiftCard { 
    id?: string;
    name?: string;
    description?: string;
    externalRefId?: string;
    dynamicDays?: number;
    partialUse?: boolean;
    isOpenValue?: boolean;
    terms?: string;
    value?: number;
    createdBy?: string;
    updatedBy?: string;
    schemeId?: string;
    isDynamic?: boolean;
    from?: Date;
    to?: Date;
    validAfter?: number;
    validFor?: number;
    isPurchasable?: boolean;
    purchasingOutletId?: string;
    purchasingTransactionDescription?: string;
    createdDate?: Date;
    updatedDate?: Date;
}