<div class="sheet stats-card">
  <div class="d-flex flex-column justify-content-between">
    <div class="d-flex flex-row justify-content-between">
      <mat-icon color="accent">{{icon}}</mat-icon>
      <mat-icon [matTooltip]="tooltip">info</mat-icon>
    </div>
    <div class="d-flex flex-column mt-3">
      <h3>{{value}}<small *ngIf="secondaryValue">&nbsp;-&nbsp;{{secondaryValue}}</small></h3>
      <small>{{label}}</small>
    </div>
  </div>
</div>
