import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {ActionConnectorInterface} from '../../stepper/actionConnector.interface';
import {ActionType} from '../../stepper/action.enum';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {EmailInsertView, GiftCard, GiftCardService, SmsService} from '../../../swagger';
import {CommonService} from '../../../services/common.service';
import {NestedOutlets, SessionService} from '../../../services/session.service';
import {MainActionInterface} from '../main-action.interface';
import {tap} from 'rxjs/operators';
import {PromiseService} from 'src/app/services/promise.service';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'loyale-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.scss']
})
export class GiftCardComponent implements OnInit, MainActionInterface {

  awaitingPost = false;
  awaitingGet = false;

  giftCard: GiftCard = <GiftCard>{};
  editorOptions = {
    emoji: true
  };

  giftCardForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', [Validators.required]),
    externalRefId: new UntypedFormControl('', []),
    terms: new UntypedFormControl('', []),
    isOpenValue: new UntypedFormControl(false, []),
    partialUse: new UntypedFormControl(false, []),
    value: new UntypedFormControl(null, [Validators.required]),
    validFor: new UntypedFormControl(null, []),
    validAfter: new UntypedFormControl(null, []),
    from: new UntypedFormControl(null, []),
    to: new UntypedFormControl(null, []),
    isDynamic: new UntypedFormControl(false, [Validators.required]),
    isPurchasable: new UntypedFormControl(false, []),
    purchasingOutletId: new UntypedFormControl(null, []),
    purchasingTransactionDescription: new UntypedFormControl('', [])
  });

  giftCardCopy: any = null;

  @Input() giftCardId: string = null;

  nestedOutlets: NestedOutlets[] = [];
  outletsFilter = null;
  filteredOutlets: NestedOutlets[] = [];

  public readonly dataUpdate: BehaviorSubject<ActionConnectorInterface> = new BehaviorSubject<ActionConnectorInterface>({
    actionType: ActionType.GIFT_CARD,
    actionData: null,
    formValid: null
  });

  @Output() emailData: EventEmitter<any> = new EventEmitter(this.giftCardForm.value);
  @Output() entityChanges: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    private giftCardService: GiftCardService,
    public commonService: CommonService,
    public promiseService: PromiseService,
    public sessionService: SessionService) {
  }

  ngOnInit() {
    if (this.giftCardId) {
      this.getGiftCard();
    }

    this.promiseService.getNestedOutlets().then(res => {
      this.nestedOutlets = res;
      this.filteredOutlets = this.nestedOutlets;
    });

    this.giftCardForm.valueChanges.subscribe(changes => {
      this.dataUpdate.next(
        {
          actionType: ActionType.GIFT_CARD,
          actionData: this.giftCardForm.value,
          formValid: this.giftCardForm.valid
        }
      );

      if (this.giftCardForm.touched) {
        this.emailData.next(changes);
      }

      this.giftCard = {...this.giftCard, ...this.giftCardForm.value};

      this.entityChanges.next(JSON.stringify(this.giftCardCopy) !== JSON.stringify(this.giftCard));
    });
  }

  getGiftCard() {
    this.awaitingGet = true;
    this.giftCardService.apiGiftCardGiftCardIdGet(this.giftCardId).subscribe(res => {
      this.awaitingGet = false;

      this.giftCard = res;
      this.giftCardCopy = res;
      this.giftCardForm.patchValue({...res});
      // after value would be fetch make check to update validity
      this.onOpenChange();
      this.onDynamicExpiryChange();
    }, err => {
      this.commonService.errorHandler(err);
      this.awaitingGet = false;
    });
  }

  outletsSearchChanges() {
    const nestedOutletsCopy = cloneDeep(this.nestedOutlets);

    if (!nestedOutletsCopy) {
      return;
    }

    let filter = this.outletsFilter;

    if (filter === '') {
      this.filteredOutlets = JSON.parse(JSON.stringify(this.nestedOutlets));
      return;
    } else {
      filter = filter.toLowerCase();

      nestedOutletsCopy.forEach((outlet: any) => {
        outlet.outlets = outlet.outlets.filter(element => element.name.toLowerCase().indexOf(filter) > -1);
      });

      this.filteredOutlets = nestedOutletsCopy.filter(outlet => outlet.outlets.length !== 0);
    }
  }

  onOpenChange() {
    if (this.giftCardForm.get('isOpenValue').value === false) {
      this.giftCardForm.get('value').setValidators([Validators.required]);
      this.giftCardForm.get('value').updateValueAndValidity();
    }
    if (this.giftCardForm.get('isOpenValue').value === true) {
      this.giftCardForm.get('value').clearValidators();
      this.giftCardForm.get('value').updateValueAndValidity();
      this.giftCardForm.patchValue({value: null});
    }
  }

  onIsPurchasableChange() {
    if (this.giftCardForm.get('isPurchasable').value === false) {
      this.giftCardForm.get('purchasingOutletId').setValue(null);
      this.giftCardForm.get('purchasingOutletId').setValidators(null);
      this.giftCardForm.get('purchasingTransactionDescription').setValue(null);
      this.giftCardForm.updateValueAndValidity();
    }

    if (this.giftCardForm.get('isPurchasable').value === true) {
      this.giftCardForm.get('purchasingOutletId').setValue(null);
      this.giftCardForm.get('purchasingOutletId').markAsTouched();
      this.giftCardForm.get('purchasingOutletId').setValidators([Validators.required]);
      this.giftCardForm.get('purchasingTransactionDescription').setValue(null);
      this.giftCardForm.updateValueAndValidity();
    }
  }

  onDynamicExpiryChange() {
    if (this.giftCardForm.get('isDynamic').value) {
      this.giftCardForm.get('from').clearValidators();
      this.giftCardForm.get('from').updateValueAndValidity();

      this.giftCardForm.get('to').clearValidators();
      this.giftCardForm.get('to').updateValueAndValidity();

      this.giftCardForm.patchValue({from: null});
      this.giftCardForm.patchValue({to: null});

      this.giftCardForm.get('validFor').setValidators([Validators.required]);
      this.giftCardForm.get('validFor').updateValueAndValidity();

      this.giftCardForm.get('validAfter').updateValueAndValidity();
    } else {
      this.giftCardForm.get('validFor').clearValidators();
      this.giftCardForm.get('validFor').updateValueAndValidity();

      this.giftCardForm.get('validAfter').clearValidators();
      this.giftCardForm.get('validAfter').updateValueAndValidity();

      this.giftCardForm.patchValue({validFor: null});
      this.giftCardForm.patchValue({validAfter: null});

      this.giftCardForm.get('from').setValidators([Validators.required]);
      this.giftCardForm.get('from').updateValueAndValidity();

      this.giftCardForm.get('to').setValidators([Validators.required]);
      this.giftCardForm.get('to').updateValueAndValidity();
    }
  }

  public createNew(): Observable<any> {
    if (this.giftCardForm.valid) {
      this.awaitingPost = true;

      return this.giftCardService.apiGiftCardPost(this.giftCardForm.value, this.sessionService.schemeId).pipe(
        tap(res => {
          this.awaitingPost = false;

          this.dataUpdate.next(
            {
              actionType: ActionType.GIFT_CARD,
              actionData: res,
              formValid: this.giftCardForm.valid
            }
          );
        })
      );
    } else {
      return null;
    }
  }

  public update(): Observable<any> {
    this.giftCardForm.markAllAsTouched();
    if (this.giftCardForm.valid) {
      this.awaitingPost = true;
      return this.giftCardService.apiGiftCardPut(this.giftCard, this.sessionService.schemeId).pipe(
        tap(res => {
          this.awaitingPost = false;

          this.dataUpdate.next(
            {
              actionType: ActionType.GIFT_CARD,
              actionData: res,
              formValid: this.giftCardForm.valid
            }
          );

          this.giftCard = res;
          this.giftCardCopy = res;

          this.giftCardForm.patchValue({...res});
        })
      );
    } else {
      return null;
    }
  }

  public getData(): ActionConnectorInterface {
    this.giftCardForm.markAllAsTouched();

    return {
      actionType: ActionType.GIFT_CARD,
      actionData: this.giftCardForm.value as EmailInsertView,
      formValid: this.giftCardForm.valid
    };
  }

  dateRangeOnChange(event: any) {
    this.giftCardForm.get('from').setValue(event[0]);
    this.giftCardForm.get('to').setValue(event[1]);
  }

}
